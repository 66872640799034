.textBlock {
  align-self: center;
  width: 80%;
  font-size: 200%;
}

.image {
  margin: 16px;
  width: 60%;
}

@media (max-width: 1200px) {
  .textBlock {
    font-size: 150%;
  }
}

@media (max-width: 768px) {
  .image {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .image {
    width: 60%;
  }

  .textBlock {
    font-size: 100%;
  }
}